.root {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2rem;
  height: 50vh;
}

.root > div {
  width: 20rem;
}

.root > div > div:not(:last-child) {
  margin-bottom: 2rem;
}

.root > div > div:last-child {
  display: flex;
  column-gap: 1rem;
}

.root > div > div > button {
  width: fill;
  height: 2.5rem;
}

.root > div:nth-of-type(1) > img {
  margin-bottom: 3rem;
  width: 10rem;
}

@media only screen and (max-width: 768px){
  .root {
    padding: 32px;
    margin-top: 40px;
  }
  
  .root > div {
    width: 320px;
    max-width: 90%;
  }
  
  .root > div > div:not(:last-child) {
    margin-bottom: 32px;
  }

  .root > div > div:last-child {
    column-gap: 16px;
  }
  
  .root > div > div > button {
    height: 40px;
  }
  
  .root > div:nth-of-type(1) > img {
    margin-bottom: 48px;
    width: 160px;
  }
}