.root {
  padding: 1.25rem 1rem;
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  border-radius: 0.25rem;
}

.root > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  background: radial-gradient(131.2% 109.82% at 49.68% 144.64%, #FCE6DD 0%, #FFFFFF 100%) !important;
  border: 1px solid var(--gray-800);
  font-weight: 700;
}

.root > .usetoggle {
  margin-top: 0.5rem;
}

.root > div:last-child > .switch span::before{
  left: 0.125rem;
}


@media only screen and (max-width: 768px) {
  .root {
    padding: 20px 16px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    width: auto;
    white-space: nowrap;
  }

  .root > .usetoggle {
    margin-top: 8px;
  }
  
  .root > div:last-child > .switch span::before{
    left: 2px;
  }
}