.root {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.root > label {
  color: var(--grey-color-1);
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: bold;
}

.root > div {
  width: 8rem;
  height: 8rem;
  border: dashed gray 1px;
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.625rem;
}

.root span {
  font-size: .75rem;
  font-weight: 400;
}

.root > div > label {
  background-color: var(--primary-color);
  border-radius: .25rem;
  border: none;
  color: black;
  display: flex;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  cursor: pointer;
  width: fill;
  height: 100%;
}

.root > div > label > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.root > div > label > div > img {
  height: 1rem;
}

.root > div > label > .thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.root > div > label > input {
  display: none;
}

.root button {
  width: 1rem;
  border-radius: 0.5rem;
  position: relative;
  bottom: 7.5rem;
  left: 5.5rem;
}

.root button img {
  width: 0.5rem;
  height: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .root > label {
    font-size: 12px;
    line-height: 16px;
  }
  
  .root > div {
    width: 90px;
    height: 90px;
    border: dashed gray 1px;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 10px;
  }
  
  .root span {
    font-size: 14px;
  }
  
  .root > div > label {
    border-radius: 4px;
    font-size: 14px;
  }
  
  .root > div > label > div > img {
    height: 16px;
  }
  
  .root button {
    width: 16px;
    border-radius: 8px;
    bottom: 87px;
    left: 55px;
  }
  
  .root button img {
    width: 8px;
    height: 8px;
  }
}