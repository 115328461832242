.switch {
  position: relative;
  display: inline-block;
  width: 2.875rem;
  height: 1.5rem;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  top: 2px; 
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--primary);
}

input:focus + .slider {
  box-shadow: 0 0 0.0625rem var(--primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.375rem);
  -ms-transform: translateX(1.375rem);
  transform: translateX(1.375rem);
}

.slider.round {
  border-radius: 2.125rem;
  padding: 0.125rem;
}

.slider.round:before {
  border-radius: 50%;
}

@media only screen and (max-width: 768px) {
  .switch {
    width: 46px;
    height: 24px;
  }
  
  .slider:before {
    height: 20px;
    width: 20px;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--primary);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
  
  .slider.round {
    border-radius: 34px;
    padding: 2px;
  }
}