.root {
  padding: 1.5rem 7.75rem 1rem;
  text-align: left;
}

.root > h3,
.categoryDescription > div > h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.root > h5,
.categoryDescription > div > h5 {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 2.5rem;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.filters > .groupButton {
  display: flex;
  align-items: center;
}

.groupButton > button:not(:last-child) {
  margin-right: 1rem;
}

.activeButton {
  display: flex;
  align-items: center;
}

.activeButton > img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.galleries {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
}

.inputLink {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-bottom: 2.5rem;
}

.inputLink > button {
  padding: 0.625rem 1rem;
  height: fit-content;
  align-self: flex-end;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  height: 2.875rem;
}

@media only screen and (max-width: 768px) {
  .root {
    margin-top: 0px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .root > h3,
  .categoryDescription > div > h3 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 0 40px;
  }

  .root > h5,
  .categoryDescription > div > h5 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
    padding: 0 40px;
  }
  
  .filters {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 32px;
  }
  
  .filters > div {
    padding: 0 40px;
    width: fill;
  }
  
  .filters > .groupButton {
    display: flex;
    align-items: center;
    margin-top: 32px;
    overflow: auto;
    padding: 0 40px;
    padding-bottom: 20px;
  }
  
  .groupButton > button:not(:last-child) {
    margin-right: 16px;
  }

  .activeButton {
    display: flex;
    align-items: center;
  }
  
  .activeButton > img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .categoryDescription {
    margin-bottom: 24px;
    flex-direction: column;
    align-items: center;
  }

  .categoryDescription > div {
    text-align: center;
  }
  
  .categoryDescription > div > h3 {
    margin-bottom: 8px;
  }

  .categoryDescription > div > h5 {
    max-width: unset;
  }
  
  .categoryDescription > button {
    padding: 16px;
    border-radius: 8px;
  }

  .categoryDescription > button > p > img {
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }
  
  .galleries {
    padding: 0 40px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
  }

  .inputLink {
    column-gap: 8px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    padding: 0 40px;
  }

  .inputLink > div {
    width: fill;
  }
  
  .inputLink > button {
    padding: 10px 16px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    height: 46px;
    width: fill;
  }
}