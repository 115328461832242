.root {
  text-align: left;
}

.root > label {
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.root > div > textarea {
  border-radius: 0.5rem;
  border: 1px solid #D1D1D6;
  padding: 0.625rem 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  width: fill;
  color: #26272B;
  height: 10rem;
}

.root > div > textarea::-webkit-input-placeholder {  color: #97A4B4; font-weight: 400; }
.root > div > textarea:-moz-placeholder { color: #97A4B4; font-weight: 400; }
.root > div > textarea::-moz-placeholder { color: #97A4B4; font-weight: 400; }
.root > div > textarea::-ms-input-placeholder { color: #97A4B4; font-weight: 400; }

.root > div > textarea:focus-visible {
  outline: none;
}

.wrapper > .error {
  border: 1px solid #B4000F;
}

.root > .error {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #B4000F;
}

.root > .error > img {
  height: 0.75rem;
  width: 0.75rem;
  margin-right: 0.25rem;
}

@media only screen and (max-width: 768px) {
  .root > label {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  
  .root > div > textarea {
    border-radius: 8px;
    padding: 10px 8px;
    font-size: 16px;
    line-height: 24px;
  }

  .root > div > textarea::-webkit-input-placeholder { font-size: 14px }
  .root > div > textarea:-moz-placeholder { font-size: 14px }
  .root > div > textarea::-moz-placeholder { font-size: 14px }
  .root > div > textarea::-ms-input-placeholder { font-size: 14px }
  
  .root > .error {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
  }
  
  .root > .error > img {
    height: 12px;
    width: 12px;
    margin-right: 4px;
  }  
}