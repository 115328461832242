.root {
  background-color: var(--white-bg);
  border-radius: 0.5rem;
  padding: 1rem;
  min-width: 17rem;
  width: 17rem;
  height: 100%;
  box-shadow: 0px 11px 12px -4px rgba(138, 132, 130, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem;
}

.new {
  background-color: rgba(255, 255, 255, 1);
}

.root > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
  height: fill;
}

.root > div:nth-child(1) > span div {
  height: 8rem;
  width: 8rem;
}


.root > div:nth-child(1) > span img {
  width: fill;
  height: fill;
  border-radius: 0.25rem;
  object-fit: contain;
}

.root > div:nth-child(1) > div {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-800);
  width: fill;
  font-weight: 800;
  margin-top: 1rem;
}

.root > div:nth-child(2) {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.root > div:nth-child(2) > a {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
}

.root > div:nth-child(2) > a:hover {
  color: #944EA1;
  text-decoration: underline;
}

.root > div:nth-child(2) > a:focus {
  color: #3F2245;
}

.root > button {
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 768px) {
  .root {
    border-radius: 8px;
    padding: 16px;
    min-width: 272px;
    width: 272px;
    box-shadow: 0px 11px 12px -4px rgba(138, 132, 130, 0.8);
    margin: 8px;
  }
  
  .root > div:nth-child(1) {
    margin-bottom: 16px;
  }
  
  .root > div:nth-child(1) > span div {
    height: 128px;
    width: 128px;
  }
  
  
  .root > div:nth-child(1) > span img {
    border-radius: 4px;
  }
  
  .root > div:nth-child(1) > div {
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
    margin-top: 16px;
  }
  
  .root > div:nth-child(2) > a {
    font-size: 12px;
    line-height: 16px;
  }

  .root > button {
    margin: 8px 0;
    padding: 16px;
    border-radius: 4px;
  }
}