.root {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root > h1 {
  margin-bottom: 2.5rem;
  text-align: left;
  align-self: flex-start;
  margin-left: 1rem;
}

.root > div:nth-of-type(1) {
  display: flex;
  align-self: flex-start;
  padding-left: 1rem;
  margin-bottom: 1.5rem;
} 

.root > div:nth-of-type(1) > button {
  margin-right: 1rem;
}

.root > div:nth-of-type(2) {
  padding: 1rem;
  width: fit-content !important;
  height: fit-content !important;
}

.root > div:nth-of-type(2) table {
  background-color: white;
  font-size: 14px;
}

.root .error {
  color: var(--primary) !important;
  font-weight: 700;
}

.root > div table > thead > tr > th {
  /* background-color: var(--primary) !important;
  color: white; */
}

.actionButtons {
  display: flex;
  column-gap: 1rem;
}

.root > div table button {
  padding: 0.5rem 1rem;
  font-size: 0.625rem;
}


.root > div table input {
  font-size: 14px;
  line-height: 14px;
  padding: 4px 8px;
  vertical-align: middle;
}

.buttonDownload {
  align-self: flex-start;
  margin-left: 1rem;
}

.fieldPath {
  display: flex;
  align-items: center;
  column-gap: 0.125rem;
}

.fieldPath > img {
  width: 0.75rem;
  height: 0.75rem;
  margin-top: -0.5rem;
}

.fieldPath > p {
  padding-top: 0.2rem;
}

.patherror {
  border-color: darkred !important;
}

.actionButtons > button,
.editAction {
  font-size: 14px !important;
  padding: 8px 16px !important;
}

@media only screen and (max-width: 768px) {
  .root {
    padding: 72px 16px;
  }
  
  .root > h1 {
    margin-bottom: 40px;
    margin-left: 16px;
  }
  
  .root > div:nth-of-type(1) {
    padding-left: 16px;
    margin-bottom: 24px;
  } 
  
  .root > div:nth-of-type(1) > button {
    margin-right: 16px;
  }
  
  .root > div:nth-of-type(2) {
    padding: 16px;
  }
  
  .root > div:nth-of-type(2) table {
    font-size: 14px;
  }  

  .actionButtons {
    column-gap: 16px;
  }
  
  .root > div table button {
    padding: 8px 16px;
    font-size: 14px;
  }
  
  .root > div table input {
    font-size: 14px;
    line-height: 14px;
    padding: 14.4px;
  }

  .buttonDownload {
    align-self: flex-start;
    margin-left: 16px;
    padding: 16px;
  }
}